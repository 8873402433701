.main-body{
  display: flex;
}

.uploadcsv {
  width: 100%;
  margin-right:10px;
  margin-left: 245px;
}

.top {
  margin-bottom: 20px;
}

.uploadbutton {
  background-color: #007bff; /* Primary button background color */
  color: #fff; /* Text color */
  padding: 10px 0px; /* Padding for the button */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show a pointer cursor on hover */
  font-size: 16px; /* Font size */
  width: 120px;
  margin-left:90%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.clearbutton{
  background-color: #dd3e1e; /* Primary button background color */
  color: #fff; /* Text color */
  padding: 10px 0px; /* Padding for the button */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show a pointer cursor on hover */
  font-size: 16px; /* Font size */
  width: 120px;
  margin-left:10%;
  margin-top: 20px;
  margin-bottom: 20px; 
}
.table{
  width: 100%;
}
th{
  text-align: left;
}