.csv1{
    background-color: #007bff; /* Primary button background color */
    text-decoration: none;
    color: #fff; /* Text color */
    padding: 8px 15px 12px 10px; /* Padding for the button */
    border: none; /* Remove the default button border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Show a pointer cursor on hover */
    font-size: 15px; /* Font size */
    margin-bottom: 0px;
    margin-left: 20px;
}