.main-body{
    display: flex;
  }
  .report{
    width: 100%;
    margin-right:10px;
    margin-left: 245px;
  }
  
  .filter_section{
      padding:12px 0 0 12px;
      margin-bottom: 10px;
  }
  .filter {
    background-color: #007bff; /* Primary button background color */
      color: #fff; 
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 20px;
      margin-left: 90%;
    }
    .filter:hover {
      background-color: #0056b3;
    }
 

.copy-button{
    background-color:rgb(50, 199, 65);
    color: black;
    border: none;
    padding: 10px 6px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 88%;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 5px;
  } 
.copy-button:hover {
      background-color:rgb(17, 88, 24);
    }