
.closebutton{
  background-color: #c74747; /* Button background color for clearing */
  color: #fff; /* Text color */
  padding: 10px 30px; /* Padding for the button */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show a pointer cursor on hover */
  font-size: 16px; /* Font size */
  margin-right: 5px;
}
.closebutton:hover {
  background-color: #8c3131; /* Darker shade of the clear button on hover */
}

