.main-body{
    display: flex;
  }
  .updateuser{
      width:90%;
      margin-right:10px;
      margin-left: 245px;
  }
  .buttonsupdateuser{
    width: 25%;
    margin-left: 75%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    }
  