.main-body{
    display: flex;
  }
  .users {
    width: 100%;
    margin-right:10px;
    margin-left: 245px;
  }
  .Adduserhead {
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
    background-color:#007bff;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    width: 14%;
    height: 50px;
    font-family: 'Times New Roman', Times, serif;
  }
  .Adduserhead:hover {
    background-color:#0a70de;
    
  }
  .Adduser{
    color: white;
    padding: 15px 30px;
    text-decoration:none;
    width: 100%;
    font-weight: bold;
  }
  .Adduser:hover{
    color: black;
  }

  .actions {
    display: flex;
  }
  
  .update1{
    font-size: 22px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    color: #007bff;
    margin-right:10px ;
  }
  .delete {
   
    font-size: 19px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    color: #ff120e;
  }
  
 
  
 
  thead {
    border-bottom: 1px solid #000;
    
  }
  
  th {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: center;
  }
  td{
    font-size: 14px;
    text-align: left;
  }
  .action-btn{
    text-align: center;
  }

  