.main-body{
  display: flex;
}
.report{
  width: 100%;
  margin-left: 245px;
}

.filter_section{
    padding:12px 0 0 12px;
    margin-bottom: 10px;
}
.filter {
  background-color: #007bff; /* Primary button background color */
    color: #fff; 
    border: none;
    padding: 0px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 90%;
  }
  
  .filter:hover {
    background-color: #0056b3;
  }

  .reposemployeereport{
    display: flex;
    margin-top: 20px;
    width: 300px;
    margin-bottom: 10px;
   margin-left: 1680px;
  }
.pdf{
  background-color: #007bff; /* Primary button background color */
  color: #fff; /* Text color */
  padding: 0px 20px; /* Padding for the button */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show a pointer cursor on hover */
  font-size: 16px;
}

.pdf:hover {
    background-color:rgb(17, 88, 24);
  }
