.sidebar {
    background: #000; 
    color: #fff;
    height: 100vh;
    width: 50px;
    z-index: 1;
    position:fixed;
    overflow-y: auto;
    transition: width 0.3s;
    border-left: 1px solid white;
}

.sidebar::-webkit-scrollbar {
    width: 0;
    background: transparent; /* You can also set this to 'none' if you want to completely remove the scrollbar */
}
.top_section{
    display: flex;
    align-items:center;
    padding:12px 0px 0px 10px;   
}
.logo{
    font-size: 24px;
    padding-top: 3px;
    padding-bottom: 1px;
    cursor: pointer; 
}
.bars{
    display: flex;
    font-size: 22px;
    border: #fff;
    cursor: pointer; 
}
.link{
    display: flex;
    text-decoration: none;
    color: #fff;
    padding: 7px 0px 7px 14px;
    gap: 15px;
    transition: all 0.5s;
}
.submenu-link{
    display: inline-flex;
    text-decoration: none;
    color: #fff;
    padding: 6px 12px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background:#4682B4;
    color: #000;
    transition: all 0.5s;
    cursor: pointer; 
}
.submenu-link:hover{
    background:#4682B4;
    color: #000;
    transition: all 0.5s;
    cursor: pointer; 
}
.active{
    background: #4682B4;
    color: #000;
}
.icon1{
    font-size: 16px;
    margin :0
}
.icon2{
    font-size: 16px;
    margin :0
}
.icon3{
    font-size: 13px;
    margin-right:10px ;
}

.link_text{
    width:160px;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 3px;
}
.submenu_link_text{
    width:200px;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 1px;
}
.active{
    background-color: #4682B4;  
}
.submenu-link.active {
    background-color: #4682B4;
}
.link.Reports.active {
    background-color: #4682B4;
}

  .logout_section{
    display: flex;
    color: #fff; 
    font-size: 16px;
    margin: 40px 2px 15px 5px;
  }
  .logoutbtn{
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  position: relative;

  }
  .logoutbtn:hover{
    color: rgb(235, 179, 179);
  }
  .dropdown-item{
    display: flex;
    text-decoration: none;
    color: #fff;
    padding: 7px 0px 7px 14px;
    gap: 15px;
    transition: all 0.5s;
}
.dropdown-item:hover{
    background:#4682B4;
    color: #000;
    transition: all 0.5s;
    cursor: pointer; 
}