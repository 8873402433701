.main-body{
  display: flex;
}

.dashboard {
  margin-left: 245px;

}

.card-employees {
  background: #4682B4;
  padding: 5px;
}
.card-workLocations {
  background: #4682B4;
  padding: 5px;
}

.card-designation {
  background: #4682B4;
  padding: 5px;
}

.card-salary {
  background:#4682B4;
  padding: 5px;
}
.card {
  padding-top:  20px;
  padding-bottom: 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  }
  
  .card h2 {
    color: rgb(253, 253, 253);
    text-decoration: none;
  }
  .details{
    margin-left: 5px;
    margin-top : 5px;
    color: rgb(253, 253, 253);
    font-size: 18px;
  }

