/* Define a container to hold the buttons */
.hemasmcint_buttons {
  display: flex;
  margin-top: 20px;
  width: 300px;
  margin-bottom: 20px;
  margin-left: 2150px;
}
.genarate{
  background-color: #007bff; /* Primary button background color */
  color: #fff; /* Text color */
  padding: 0px 20px; /* Padding for the button */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show a pointer cursor on hover */
  font-size: 16px;
}
.update{
  background-color: #408d1d; /* Button background color for clearing */
  color: #fff; /* Text color */
  padding: 10px 30px; /* Padding for the button */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show a pointer cursor on hover */
  font-size: 16px; /* Font size */
  margin-right: 5px;
}
.checkcsv {
  background-color: #c74747; /* Button background color for clearing */
  color: #fff; /* Text color */
  padding: 10px 30px; /* Padding for the button */
  border: none; /* Remove the default button border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show a pointer cursor on hover */
  font-size: 16px; /* Font size */
  margin-right: 5px;
  text-decoration: none;
}
.genarate:hover {
  background-color: #0056b3; /* Darker shade of the primary color on hover */
}
.update:hover {
  background-color: #24500f; /* Darker shade of the clear button on hover */
}
.checkcsv:hover {
  background-color: #8c3131; /* Darker shade of the clear button on hover */
}
