/* Login.css */
.main_body{
    background-color: beige;
}
.container {
   margin-left: 30%;
    justify-content: center;
    align-items: center;
    height: 100vh;
 
  }
  .title{
    margin-left: 45px;
    font-size: 55px;
  }
  .login-form {
    margin-left: 200px;
    margin-top: 40px;
    width: 900px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  /* Add any other custom styles here */
  