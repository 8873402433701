.main-body{
  display: flex;
}
.employee {
  width: 100%;
  margin-right:10px;
  margin-left: 245px;
}

.actions {
  display: flex;
}

.upd{
  font-size: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #007bff;
  margin-right: 10px;
}
.del {
  font-size: 19px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #ff120e;
}

.srch input{
  font-size: 16px;
  height: 38px;
  width: 300px;
  margin-bottom: 35px;
}


.action-btn{
  text-align: center;
}

.select{
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
}
.select:hover{
  background-color: #0c539e;
}