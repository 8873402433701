.main-body{
  display: flex;
}
.report{
  width: 100%;
  margin-right:10px;
  margin-left: 245px;
}

.filter_section{
    padding:12px 0 0 12px;
    margin-bottom: 10px;
}
.filter {
  background-color: #007bff; /* Primary button background color */
    color: #fff; 
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 80%;
  }
  
  .filter:hover {
    background-color: #0056b3;
  }


  .repos{
    margin-left: 1580px;
    width: 260px;
    display: inline-block;
  }


.csv {
  background-color:rgb(50, 199, 65);
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 40px;
  font-weight: bold;
  text-decoration: none;
} 

.pdf:hover {
    background-color:rgb(17, 88, 24);
  }
.csv:hover {
    background-color:rgb(17, 88, 24);
  }