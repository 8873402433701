.main-body{
    display: flex;
  }
  .designations{
    width:100%;
    margin-right:10px;
    margin-left: 245px;
  }
  .Addldesignationhead {
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
    background-color:#007bff;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    width: 24%;
    height: 50px;
    font-family: 'Times New Roman', Times, serif;
  }
  .Add:hover {
    background-color:#0a70de;
    
  }
  .Addldesignation{
    color: white;
    padding: 15px 30px;
    text-decoration:none;
    width: 100%;
    font-weight: bold;
  }
  .Addldesignation:hover{
    color: black;
  }

  .actions {
    display: flex;
  }
    .update1{
      font-size: 22px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      color: #007bff;
      margin-right:10px ;
    }
    .delete {   
      font-size: 19px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      color: #ff120e;
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: right;
    }
    
    .search input {
      width: 400px;
      height: 43px;
      padding: 5px;
      font-size: 16px;
      border: 1px solid #007bff;
    }
    
    .search-button {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
    }
    .search-button:hover {
      background-color: #0366d6;
    }
    thead {
      border-bottom: 1px solid #000;
    }
    
    th {
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      text-align: center;
    }
    td{
      font-size: 14px;
      text-align: left;
    }
    .action-btn{
      text-align: center;
    }