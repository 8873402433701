.main-body{
  display: flex;
}
.updateemployee{
  width: 100%;
  margin-right:10px;
margin-left: 245px;
}
  .updt {
    margin-left: 60%;
    width: 250px;
    background-color: #408d1d; /* Button background color for clearing */
    color: #fff; /* Text color */
    padding: 10px 30px; /* Padding for the button */
    border: none; /* Remove the default button border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Show a pointer cursor on hover */
    font-size: 16px; /* Font size */
    margin-bottom: 15px;
  
      }