.main-body{
  display: flex;
}
.addemployee{
  width: 100%;
  margin-right:10px;
  margin-left: 245px;
}
.personal_details, .company_details, .bank_details, .dependant_details{
    margin: 20px 0 20px 0px;
    padding:0 20px 20px 20px;
    border : 2px solid #333333;
    border-radius: 12px;
    font-size: 12px;
}
.personal_details h3, .company_details h3, .bank_details h3, .dependant_details h3{
    font-size: 23px;
    font-weight: 600;
    color: #333333;
    margin: 20px 0 20px 0;
}
.dependant_details h4{
  font-size: 18px;

}
.form-label{
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 5px;
}
.form-check-label{
    font-size: 17px;
    color: #333333;
}
.gender_full, .marital_full, .employeetype_full, .paytype_full, .workshift_full, .paygrade_full{
    display: inline-block;
}
.gender_cat, .marital_cat, .employeetype_cat, .paytype_cat, .workshift_cat, .paygrade_cat{
    margin-left: 20px;
    display: flex;
    gap: 30px;
}
.add_buttons {
   
    width: 50%;
    display: flex;
    justify-content: center;
    margin-left: 33%;
    margin-top: 20px;
    margin-bottom: 20px;
   
  }
  
  .save-button {
    flex: 1;
    margin-left: 50px;
    margin-right: 10px;
    background-color: #007bff; /* Primary button background color */
    color: #fff; /* Text color */
    padding: 10px 20px; /* Padding for the button */
    border: none; /* Remove the default button border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Show a pointer cursor on hover */
    font-size: 16px; /* Font size */
    width: 100px;
    margin-bottom: 20px;
    }
  
  .reset-button {
    flex: 1;
    background-color: #DC143C; /* Primary button background color */
    color: #fff; /* Text color */
    padding: 10px 10px; /* Padding for the button */
    border: none; /* Remove the default button border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Show a pointer cursor on hover */
    font-size: 16px; /* Font size */
    width: 100px;
    margin-bottom: 20px;
  }
  .save-button:hover{
    background-color: #0056b3;
  }
  .reset-button:hover{
    background-color: #ad0325;
  }
  .btn {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  